import { TabList, TabPanels, TabPanelProps, Tabs as CTabs, StyleProps } from '@chakra-ui/react';
import React from 'react';
import { Alignment } from 'shared/types/alignment';
import { Tab, TabProps } from './components/Tab/tab.component';
import { TabSize } from './components/Tab/tab.types';

export interface TabsProps extends StyleProps {
  size?: TabSize;
  tabList: TabProps[];
  tabPanels?: JSX.Element[];
  tabPanelsStyle?: TabPanelProps;
  isFitted?: boolean;
  align?: Alignment;
  defaultIndex?: number;
  isLazy?: boolean;
  isManual?: boolean;
  index?: number;
  onChange?: (index: number) => void;
}

export const Tabs = ({ size = 'md', tabList, tabPanels, tabPanelsStyle, ...rest }: TabsProps): JSX.Element => {
  return (
    <CTabs {...rest} size={size}>
      <TabList>
        {tabList.map((tab, index) => (
          <Tab key={index} size={size} {...tab} />
        ))}
      </TabList>
      {!!tabPanels && <TabPanels {...tabPanelsStyle}>{tabPanels.map((panel) => panel)}</TabPanels>}
    </CTabs>
  );
};
