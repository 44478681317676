import React, { useRef } from 'react';
import { PopoverContent, PlacementWithLogical } from '@chakra-ui/react';
import { TextInputProps } from '../TextInput/textInput.component';
import { DatePickerProvider } from './context/datepicker.provider';
import { DateFormats } from './datepicker.types';
import { DatePickerHeader } from './components/Header/header.component';
import { DatePickerInput } from './components/Input/input.component';
import { DatePickerCalendar } from './components/Calendar/calendar.component';
import { DatePickerFooterProps, DatePickerFooter } from './components/Footer/footer.component';

/**
 *
 * Note: Be careful with dateFormat and allowing search (inputProps.IsReadOnly = false) as changing the format
 * can cause exceptions to be thrown.
 *
 * More dateFormat info: https://github.com/date-fns/date-fns/blob/main/docs/unicodeTokens.md
 */

export interface DateRange {
  startDate: Date | null;
  endDate?: Date | null;
}

export interface DatePickerProps {
  dateFormat?: string | DateFormats;
  endDate?: Date | null;
  footerProps?: DatePickerFooterProps;
  hideFooter?: boolean;
  inputProps?: Omit<TextInputProps, 'onChange' | 'value' | 'isClearable' | 'onClear'>;
  isDateRange?: boolean;
  maxDate?: Date;
  maxYear?: number;
  maxDaysInRange?: number;
  minDate?: Date;
  minYear?: number;
  onDateChange?: ({ startDate, endDate }: DateRange) => void;
  placement?: PlacementWithLogical;
  startDate: Date | null;
}

export const DatePicker = (props: DatePickerProps): JSX.Element => {
  const { isDateRange = false, hideFooter = false, footerProps, inputProps } = props;
  const calendarRef = useRef<HTMLDivElement>(null);
  const startDateInputRef = useRef<HTMLInputElement>(null);

  const scrollToYear = (): void => {
    setTimeout(() => {
      const currentYearNode = calendarRef?.current?.querySelector('.MenuList__Item.current');
      if (currentYearNode) {
        currentYearNode.scrollIntoView({ block: 'center' });
      }
    });
  };

  return (
    <DatePickerProvider calendarRef={calendarRef} {...props}>
      <DatePickerInput inputProps={inputProps} startDateInputRef={startDateInputRef} />
      <PopoverContent ref={calendarRef} shadow="md">
        <DatePickerHeader scrollToYear={scrollToYear} />
        <DatePickerCalendar />
        {isDateRange && !hideFooter && <DatePickerFooter {...footerProps} />}
      </PopoverContent>
    </DatePickerProvider>
  );
};
