import React, { ReactNode } from 'react';
import { Box, keyframes, VStack } from '@chakra-ui/react';
import { SpinnerSize } from './spinner.types';
import { SpinnerSizeMap } from './spinner.records';
import { Text, TextProps } from '../Text/text.component';
import { TextSize } from 'components/Text';

const spin = keyframes`
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg)}
  `;

export interface SpinnerProps {
  size?: SpinnerSize;
  isFullScreen?: boolean;
  label?: string;
  showLabel?: boolean;
  labelProps?: TextProps;
}

export const Spinner = ({
  size = 'md',
  isFullScreen = false,
  showLabel = false,
  label = 'Loading...',
  labelProps,
}: SpinnerProps): JSX.Element => {
  const spinnerSize = SpinnerSizeMap[size];
  const animation = `0.45s linear 0s infinite normal none running ${spin}`;
  const SpinnerContainer = ({ children }: { children: ReactNode }): JSX.Element => {
    return isFullScreen ? (
      <VStack h="100%" w="100%" pos="absolute" top="0" left="0" align="center" justify="center" bg="background.alpha">
        {children}
      </VStack>
    ) : (
      <VStack w="max-content" h="max-content" align="center" justify="center">
        {children}
      </VStack>
    );
  };

  return (
    <SpinnerContainer>
      <Box
        display="inline-block"
        animation={animation}
        border="2px"
        borderTopColor="background.primary"
        borderRightColor="background.primary"
        borderLeftColor={'transparent'}
        borderBottomColor={'transparent'}
        height={`${spinnerSize}px`}
        width={`${spinnerSize}px`}
        borderRadius={`${spinnerSize}px`}
      />
      {showLabel && (
        <Text as="p" className="Spinner__Label" size={size as TextSize} variant="subtle" {...labelProps}>
          {label}
        </Text>
      )}
    </SpinnerContainer>
  );
};
