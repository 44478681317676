import React, {
  ReactNode,
  MutableRefObject,
  useCallback,
  MouseEvent,
  KeyboardEvent,
  useMemo,
  ReactElement,
  ChangeEvent,
} from 'react';
import { Box, Flex, HStack, useMenuState } from '@chakra-ui/react';
import { MenuItemConfig } from '../../menu.config';
import { Text } from '../../../Text/text.component';
import { Checkbox } from 'components/Checkbox';
import { TypographyVariant } from 'shared/types';

export interface KMenuItemProps {
  children?: ReactNode;
  closeOnSelect?: boolean;
  groupLabel?: string;
  isDisabled?: boolean;
  isMultiSelect?: boolean;
  isPlaceholder?: boolean;
  isSelected?: boolean;
  label?: string | ReactElement;
  leftElement?: ReactNode;
  onSelect?: (selectedItem: KMenuItemProps) => void;
  shouldRetainSelection?: boolean;
  shouldTruncateText?: boolean;
  value?: string;
  variant?: TypographyVariant;
}

// These props are specific to the context passed in from the
// MenuList and should not need to be passed in by the end user
export interface ControlledMenuItemProps {
  canSelectAll?: boolean;
  handleKeyboardNavigation?: (e: KeyboardEvent<HTMLDivElement>, elementIsInputField: boolean) => void;
  menuItemRefs?: MutableRefObject<HTMLDivElement[] | null[]>;
  itemIndex?: string;
}

export type MenuItemProps = KMenuItemProps & ControlledMenuItemProps;

export const MenuItem = ({
  canSelectAll,
  children,
  isMultiSelect,
  closeOnSelect = !isMultiSelect,
  groupLabel,
  handleKeyboardNavigation = (): void => {},
  isDisabled,
  isPlaceholder,
  isSelected,
  itemIndex = '0',
  label,
  leftElement,
  menuItemRefs,
  onSelect,
  shouldRetainSelection = false,
  shouldTruncateText,
  value,
  variant,
  ...rest
}: MenuItemProps): JSX.Element => {
  const { onClose } = useMenuState();

  const handleSelect = useCallback(
    (e: MouseEvent<HTMLDivElement> | ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();

      if (isDisabled) return;

      if (value && onSelect) {
        onSelect({
          label,
          leftElement,
          value,
        });
      }

      if (closeOnSelect) {
        onClose();
      }
    },
    [value, onSelect, onClose, closeOnSelect, isDisabled, label, leftElement],
  );

  const fontColor = useMemo(() => {
    if (variant) {
      return variant;
    }
    return isSelected && shouldRetainSelection && !isMultiSelect ? 'interactive' : 'default';
  }, [isSelected, shouldRetainSelection, isMultiSelect, variant]);

  return (
    <Box
      {...MenuItemConfig}
      bg={isSelected && shouldRetainSelection && !isMultiSelect ? 'background.primaryAlpha.hover' : ''}
      className={`MenuList__Item${isSelected ? ' current' : ''}`}
      onClick={handleSelect}
      onKeyDown={(e: KeyboardEvent<HTMLDivElement>): void => handleKeyboardNavigation(e, false)}
      tabIndex={parseInt(canSelectAll ? (itemIndex += 2) : itemIndex)}
      ref={(el): HTMLDivElement | null =>
        menuItemRefs && itemIndex ? (menuItemRefs.current[parseInt(itemIndex)] = el) : null
      }
      // @ts-ignore
      disabled={isDisabled}
      {...rest}
    >
      {children ? (
        <Box onClick={handleSelect}>{children}</Box>
      ) : (
        <HStack data-testid={`menu-item-${label}`} className="MenuList__ItemContainer" justify="baseline" spacing={3} minH={5} color={`text.${fontColor}`}>
          {isMultiSelect && (
            <Flex w={4} h={4} className="MenuList__ItemCheckbox">
              <Checkbox size="md" isChecked={isSelected} onChange={handleSelect} />
            </Flex>
          )}
          {leftElement && (
            <Flex align="center" justify="center" className="MenuList__ItemLeftElement">
              {leftElement}
            </Flex>
          )}
          {typeof label === 'string' ? (
            <Text size="sm" className="MenuList__ItemLabel" variant={fontColor} isTruncated={shouldTruncateText}>
              {label}
            </Text>
          ) : (
            <Box className="MenuList__ItemLabel" position="relative" w="100%">
              {label}
            </Box>
          )}
        </HStack>
      )}
    </Box>
  );
};
