import React from 'react';
import { useModalContext } from '@chakra-ui/react';
import { Button, ButtonProps } from '../../../Button/button.component';

export interface ModalCloseButtonProps extends ButtonProps {}

export const ModalCloseButton = ({ right = 2, top = 2, size = 'md', ...rest }: ModalCloseButtonProps): JSX.Element => {
  const { onClose } = useModalContext();
  return (
    <Button
      variant="ghostNeutral"
      onClick={onClose}
      iconName="close"
      position="absolute"
      right={right}
      top={top}
      size={size}
      {...rest}
    />
  );
};
