export function hexToRgb(hex: string): { r: number; g: number; b: number } | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function isRGB(color: string): boolean {
  return !!color.match(/^rgb/);
}

export function isDark(color: string): boolean {
  if (!color) return false;
  if (color === 'transparent') return false;

  let r, g, b, hsp;

  if (isRGB(color)) {
    let m = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    if (m) {
      r = m[1];
      g = m[2];
      b = m[3];
    }
  } else {
    let rgb = hexToRgb(color);

    if (rgb) {
      r = rgb.r;
      g = rgb.g;
      b = rgb.b;
    }
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  // @ts-ignore
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  return hsp < 174;
}
