const transitionProperty: TransitionProperty = {
  common: 'background-color, border-color, color, fill, stroke, opacity, box-shadow, transform',
  colors: 'background-color, border-color, color, fill, stroke',
  dimensions: 'width, height',
  position: 'left, right, top, bottom',
  background: 'background-color, background-image, background-position',
};

interface TransitionProperty {
  common: string;
  colors: string;
  dimensions: string;
  position: string;
  background: string;
}

const transitionTimingFunction: TransitionTimingFunction = {
  'ease-in': 'cubic-bezier(0.4, 0, 1, 1)',
  'ease-out': 'cubic-bezier(0, 0, 0.2, 1)',
  'ease-in-out': 'cubic-bezier(0.4, 0, 0.2, 1)',
};

interface TransitionTimingFunction {
  'ease-in': string;
  'ease-out': string;
  'ease-in-out': string;
}

const transitionDuration: TransitionDuration = {
  'ultra-fast': '50ms',
  faster: '100ms',
  fast: '150ms',
  normal: '200ms',
  slow: '300ms',
  slower: '400ms',
  'ultra-slow': '500ms',
};

interface TransitionDuration {
  'ultra-fast': string;
  faster: string;
  fast: string;
  normal: string;
  slow: string;
  slower: string;
  'ultra-slow': string;
}

export const transition: Transition = {
  property: transitionProperty,
  easing: transitionTimingFunction,
  duration: transitionDuration,
};

export type Transition = {
  property: TransitionProperty;
  easing: TransitionTimingFunction;
  duration: TransitionDuration;
};
