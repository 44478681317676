import React from 'react';
import {
  ModalFooter as CModalFooter,
  ModalFooterProps as CModalFooterProps,
  useModalContext,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import { Button } from '../../../Button/button.component';

export interface ModalFooterButtonProps extends CModalFooterProps {
  mixpanelName?: string;
  submitText?: string;
  isLoading?: boolean;
  loadingText?: string;
  isDisabled?: boolean;
  isDestructive?: boolean;
  onSubmit?: () => void | Promise<void>;
  allowCancel?: boolean;
  onCancel?: () => void;
}

export const ModalFooterButtons = ({
  submitText = 'Submit',
  isLoading,
  loadingText,
  isDisabled,
  isDestructive,
  mixpanelName,
  children,
  onSubmit,
  allowCancel = true,
  onCancel,
  ...rest
}: ModalFooterButtonProps): JSX.Element => {
  const { onClose } = useModalContext();

  const handleCancel = (): void => {
    if (onCancel) {
      onCancel();
    }
    onClose();
  };

  return (
    <CModalFooter {...rest} pb={6}>
      <Flex w="100%">
        {allowCancel && (
          <Button variant="tertiary" onClick={handleCancel}>
            Cancel
          </Button>
        )}
        <Spacer />
        <Button
          data-mixpanel-name={mixpanelName}
          onClick={onSubmit}
          isLoading={isLoading}
          loadingText={loadingText}
          isDisabled={isDisabled}
          variant={isDestructive ? 'primaryDestructive' : 'primary'}
        >
          {submitText}
        </Button>
      </Flex>
    </CModalFooter>
  );
};
