import React from 'react';
import { ModalHeader as CModalHeader, ModalHeaderProps as CModalHeaderProps } from '@chakra-ui/react';
import { HeadingSize } from '../../../Heading/heading.types';
import { Heading } from '../../../Heading/heading.component';

export interface ModalHeaderProps extends CModalHeaderProps {
  size?: HeadingSize;
}

export const ModalHeader = ({ size = 'md', children, ...rest }: ModalHeaderProps): JSX.Element => {
  return (
    <CModalHeader px={6} pt={6} pb={2} {...rest}>
      <Heading size={size}>{children}</Heading>
    </CModalHeader>
  );
};
