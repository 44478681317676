import React, { useEffect, useRef } from 'react';
import { Flex } from '@chakra-ui/react';
import { TimeOption } from './timeOption.component';

interface ScrollContainerProps {
  options: string[];
  optionType: 'hour' | 'minute' | 'meridiem';
  selectedValue?: string;
  handleClick: (value: string) => void;
  isOpen: boolean;
}

export const ScrollContainer = ({
  options,
  optionType,
  selectedValue,
  handleClick,
  isOpen = false,
}: ScrollContainerProps): JSX.Element => {
  const itemRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen && containerRef.current && itemRef.current) {
      containerRef.current.scrollTop = itemRef.current?.offsetTop - containerRef.current?.offsetTop;
    }
  }, [isOpen]);

  return (
    <Flex
      direction="column"
      gap={1}
      ref={containerRef}
      overflowY="auto"
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      {options.map((option) => {
        return (
          <TimeOption
            key={`${optionType}-option-${option}`}
            innerRef={option === selectedValue ? itemRef : null}
            value={option}
            isSelected={option === selectedValue}
            handleClick={handleClick}
          />
        );
      })}
    </Flex>
  );
};
